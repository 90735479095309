export const lang = {
  LanguageChoice: "语言选择",
  FamiliarStyle: "中文简体",
  ComplexFont: "中文繁体",
  English: "English",
  ThaiLanguage: "ไทย", // 泰语
  SpanishLanguage: "Español", // 西班牙语
};

export const word = {
  Nomoredata: "没有更多数据",
  Loading: "加载",
  Requestfailedclickreload: "请求失败，请单击重新加载",
  Writeacomment: "写评论",
  Pleasewritesomething: "请写点东西",
  Reads: "读到",
  Likes: "喜欢",
  Comments: "评论",
  Viewall: "查看全部",
  Writecomment: "写评论",
  StartReading: "开始阅读",
  ContinueReading: "继续阅读",
  Buycoinstocontinuereading: "购买硬币继续阅读",
  Buychapter: "购买章节",
  Balance: "均衡",
  AddtoLibrary: "添加到库中",
  Tap: "水龙头",
  andthen: "然后",
  AddToHomeScreen: "添加到主屏幕",
  Autounlocknextchapter: "自动解锁下一章",
  Aa: "Aa",
  Thisisthefirstchapter: "这是第一章",
  Thisisthelastchapter: "这是最后一章",
  AllGenres: "所有类型",
  More: "更多",
  Findmorestories: "寻找更多故事",
  Title: "标题",
};

export const en = {
  NovelFun: "NovelFun",
  ADDTOHOMESCREEN: "添加到主屏幕",
  Tap: "水龙头",
  andthen: "然后",
  AddToHomeScreen: "添加到主屏幕",
  Contents: "目录",
  Tryothers: "试试其他人",
  SeeAll: "看到了吗",
  DownloadAPP: "下载应用",
  OpenAPP: "开放应用",
  HotRecs: "热门唱片",
  SigninwithFacebook: "使用Facebook登录",
  SigninwithGoogle: "登录谷歌",
  BycreatinganaccountIagreetothe: "通过创建帐户，我同意",
  UserAgreement: "用户协议",
  And: "和",
  PrivacyPolicy: "隐私政策",
  discover: "发现",
  library: "图书馆",
  profile: "轮廓",
  BacktoHomepage: "返回首页",
  OopsnoresultsfoundWhynottryothers:
    "哎呀，没有找到结果。为什么不试试其他的呢？",
  ChangeLanguage: "改变语言",
  SwitchAccount: "转换帐户",
  Login: "登录",
  YourQuestion: "你的问题",
  Submit: "提交",
  PleaseuploadpicturesinJPGformat: "请上传JPG格式的图片",
  Feedbackcontentorcontactinformationcannotbeempty:
    "反馈内容或联系信息不能为空",
  Feedbacksubmittedsuccessfully: "反馈提交成功！",
  Library: "图书馆",
  ReadTo: "念给",
  Findmorestories: "寻找更多故事",
  Cancel: "取消",
  Remove: "去除",
  Reads: "读到",
  Likes: "喜欢",
  ContinueRead: "继续阅读",
  AllTags: "所有标记",
  RemovedfromLibrary: "从图书馆移除",
  AddedtoLibrary: "添加到库中",
};
