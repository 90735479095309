/*
 * @Date: 2022-03-09 17:02:55
 * @LastEditors: xxx
 * @LastEditTime: 2022-03-09 17:07:05
 * @FilePath: \Magic-Novels\src\lang\en.js
 * @Description:
 */
// 语言选择
export const lang = {
  LanguageChoice: "Language selection",
  FamiliarStyle: "中文简体",
  ComplexFont: "中文繁体",
  English: "English",
  ThaiLanguage: "ไทย", // 泰语
  SpanishLanguage: "Español", // 西班牙语
};

export const word = {
  Nomoredata: "No more data",
  Loading: "Loading",
  Requestfailedclickreload: "Request failed, click reload",
  Writeacomment: "Write a comment",
  Pleasewritesomething: "Please write something",
  Reads: "Reads",
  Likes: "Likes",
  Comments: "Comments",
  Viewall: "View all",
  Writecomment: "Write comment",
  StartReading: "Start Reading",
  ContinueReading: "Continue Reading",
  Buycoinstocontinuereading: "Buy coins to continue reading",
  Buychapter: "Buy chapter",
  Balance: "Balance",
  AddtoLibrary: "Add to Library",
  Tap: "Tap",
  andthen: "and then",
  AddToHomeScreen: "Add To Home Screen",
  Autounlocknextchapter: "Auto-unlock next chapter",
  Aa: "Aa",
  Thisisthefirstchapter: "This is the first chapter",
  Thisisthelastchapter: "This is the last chapter",
  AllGenres: "All Genres",
  More: "More",
  Findmorestories: "Find more stories",
  Title: "Title",
};
export const en = {
  NovelFun: "NovelFun",
  ADDTOHOMESCREEN: "ADD TO HOME SCREEN",
  Tap: "Tap",
  andthen: "and then",
  AddToHomeScreen: "Add To Home Screen",
  Contents: "Contents",
  Tryothers: "Try others",
  SeeAll: "See All",
  DownloadAPP: "Download APP",
  OpenAPP: "Open APP",
  HotRecs: "Hot Recs",
  SigninwithFacebook: "Sign in with Facebook",
  SigninwithGoogle: "Sign in with Google",
  BycreatinganaccountIagreetothe: "By creating an account, I agree to the",
  UserAgreement: "User Agreement",
  And: "And",
  PrivacyPolicy: "Privacy Policy",
  discover: "discover",
  library: "library",
  profile: "profile",
  BacktoHomepage: "Back to Homepage",
  OopsnoresultsfoundWhynottryothers:
    "Oops, no results found.Why not try others?",
  ChangeLanguage: "Change Language",
  SwitchAccount: "Switch Account",
  Login: "Log in",
  YourQuestion: "Your Question",
  Submit: "Submit",
  PleaseuploadpicturesinJPGformat: "Please upload pictures in JPG format",
  Feedbackcontentorcontactinformationcannotbeempty:
    "Feedback content or contact information cannot be empty",
  Feedbacksubmittedsuccessfully: "Feedback submitted successfully !",
  Library: "Library",
  ReadTo: "Read To",
  Findmorestories: "Find more stories",
  Cancel: "Cancel",
  Remove: "Remove",
  Reads: "Reads",
  Likes: "Likes",
  ContinueRead: "Continue Read",
  AllTags: "All Tags",
  RemovedfromLibrary: "Removed from Library",
  AddedtoLibrary: "Added to Library",
};
