export const lang = {
  LanguageChoice: "語言選擇",
  FamiliarStyle: "中文简体",
  ComplexFont: "中文繁体",
  English: "English",
  ThaiLanguage: "ไทย", // 泰语
  SpanishLanguage: "Español", // 西班牙语
};

export const word = {
  Nomoredata: "沒有更多數據",
  Loading: "加載",
  Requestfailedclickreload: "請求失敗，請按一下重新加載",
  Writeacomment: "寫評論",
  Pleasewritesomething: "請寫點東西",
  Reads: "讀到",
  Likes: "喜歡",
  Comments: "評論",
  Viewall: "查看全部",
  Writecomment: "寫評論",
  StartReading: "開始閱讀",
  ContinueReading: "繼續閱讀",
  Buycoinstocontinuereading: "購買硬幣繼續閱讀",
  Buychapter: "購買章節",
  Balance: "平衡",
  AddtoLibrary: "添加到庫中",
  Tap: "輕敲",
  andthen: "然後",
  AddToHomeScreen: "添加到主荧幕",
  Autounlocknextchapter: "自動解鎖下一章",
  Aa: "Aa",
  Thisisthefirstchapter: "這是第一章",
  Thisisthelastchapter: "這是最後一章",
  AllGenres: "所有類型",
  More: "更多",
  Findmorestories: "尋找更多故事",
  Title: "標題",
};

export const en = {
  NovelFun: "NovelFun",
  ADDTOHOMESCREEN: "添加到主荧幕",
  Tap: "輕敲",
  andthen: "然後",
  AddToHomeScreen: "添加到主荧幕",
  Contents: "內容",
  Tryothers: "試試其他人",
  SeeAll: "看到了嗎",
  DownloadAPP: "下載應用",
  OpenAPP: "開放應用",
  HotRecs: "熱門唱片",
  SigninwithFacebook: "使用Facebook登入",
  SigninwithGoogle: "登入穀歌",
  BycreatinganaccountIagreetothe: "通過創建帳戶，我同意",
  UserAgreement: "使用者協定",
  And: "及",
  PrivacyPolicy: "隱私政策",
  discover: "發現",
  library: "圖書館",
  profile: "輪廓",
  BacktoHomepage: "返回首頁",
  OopsnoresultsfoundWhynottryothers:
    "哎呀，沒有找到結果。 為什麼不試試其他的呢？",
  ChangeLanguage: "改變語言",
  SwitchAccount: "轉換帳戶",
  Login: "登入",
  YourQuestion: "你的問題",
  Submit: "服從",
  PleaseuploadpicturesinJPGformat: "請上傳JPG格式的圖片",
  Feedbackcontentorcontactinformationcannotbeempty:
    "迴響內容或聯系資訊不能為空",
  Feedbacksubmittedsuccessfully: "迴響提交成功！",
  Library: "圖書館",
  ReadTo: "念給",
  Findmorestories: "尋找更多故事",
  Cancel: "取消",
  Remove: "移除",
  Reads: "讀到",
  Likes: "喜歡",
  ContinueRead: "繼續閱讀",
  AllTags: "所有標記",
  RemovedfromLibrary: "從圖書館移除",
  AddedtoLibrary: "添加到庫中",
};
