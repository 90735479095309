export const lang = {
  LanguageChoice: "เลือกภาษา",
  FamiliarStyle: "中文简体",
  ComplexFont: "中文繁体",
  English: "English",
  ThaiLanguage: "ไทย", // 泰语
  SpanishLanguage: "Español", // 西班牙语
};

export const word = {
  Nomoredata: "ไม่มีข้อมูลเพิ่มเติม",
  Loading: "น้ำหนักบรรทุก",
  Requestfailedclickreload: "การร้องขอล้มเหลวคลิกโหลด",
  Writeacomment: "แสดงความคิดเห็น",
  Pleasewritesomething: "กรุณาเขียนอะไรบางอย่าง",
  Reads: "อ่าน",
  Likes: "ชอบ",
  Comments: "แสดงความคิดเห็น",
  Viewall: "ดูทั้งหมด",
  Writecomment: "แสดงความคิดเห็น",
  StartReading: "เริ่มอ่าน",
  ContinueReading: "อ่านต่อ",
  Buycoinstocontinuereading: "ซื้อเหรียญเพื่ออ่านต่อไป",
  Buychapter: "บทซื้อ",
  Balance: "ความสมดุล",
  AddtoLibrary: "เพิ่มไปยังห้องสมุด",
  Tap: "จุกขวด",
  andthen: "จากนั้น",
  AddToHomeScreen: "เพิ่มไปยังหน้าจอหลัก",
  Autounlocknextchapter: "ปลดล็อคบทถัดไปโดยอัตโนมัติ",
  Aa: "อา",
  Thisisthefirstchapter: "นี่เป็นบทแรก",
  Thisisthelastchapter: "นี่เป็นบทสุดท้าย",
  AllGenres: "ประเภททั้งหมด",
  More: "ยิ่งขึ้น",
  Findmorestories: "ค้นหาข่าวเพิ่มเติม",
  Title: "หัวเรื่อง",
};

export const en = {
  NovelFun: "NovelFun",
  ADDTOHOMESCREEN: "เพิ่มไปยังหน้าจอหลัก",
  Tap: "จุกขวด",
  andthen: "จากนั้น",
  AddToHomeScreen: "เพิ่มไปยังหน้าจอหลัก",
  Contents: "เนื้อหา",
  Tryothers: "ลองคนอื่น",
  SeeAll: "เห็นไหม",
  DownloadAPP: "ดาวน์โหลดโปรแกรม",
  OpenAPP: "เปิดโปรแกรม",
  HotRecs: "บันทึกยอดนิยม",
  SigninwithFacebook: "เข้าสู่ระบบโดยใช้ Facebook",
  SigninwithGoogle: "เข้าสู่ระบบกูเกิล",
  BycreatinganaccountIagreetothe: "โดยการสร้างบัญชีที่ฉันเห็นด้วย",
  UserAgreement: "ข้อตกลงการใช้งาน",
  And: "ถึง",
  PrivacyPolicy: "นโยบายความเป็นส่วนตัว",
  discover: "ค้นพบ",
  library: "ห้องสมุด",
  profile: "โครงร่าง",
  BacktoHomepage: "กลับไปที่หน้าแรก",
  OopsnoresultsfoundWhynottryothers: "พระเจ้าไม่มีผล ทำไมไม่ลองอย่างอื่นล่ะ",
  ChangeLanguage: "เปลี่ยนภาษา",
  SwitchAccount: "บัญชีแปลงสภาพ",
  Login: "ล็อกอิน",
  YourQuestion: "ปัญหาของคุณ",
  Submit: "การเชื่อฟัง",
  PleaseuploadpicturesinJPGformat: "กรุณาอัปโหลดภาพในรูปแบบ JPG",
  Feedbackcontentorcontactinformationcannotbeempty:
    "ความคิดเห็นหรือข้อมูลที่ติดต่อไม่สามารถว่างเปล่า",
  Feedbacksubmittedsuccessfully: "ความคิดเห็นที่ส่งเรียบร้อยแล้ว",
  Library: "ห้องสมุด",
  ReadTo: "ทำนาย",
  Findmorestories: "ค้นหาข่าวเพิ่มเติม",
  Cancel: "ยกเลิก",
  Remove: "เอาออก",
  Reads: "อ่าน",
  Likes: "ชอบ",
  ContinueRead: "อ่านต่อ",
  AllTags: "เครื่องหมายทั้งหมด",
  RemovedfromLibrary: "เอาออกจากห้องสมุด",
  AddedtoLibrary: "เพิ่มไปยังห้องสมุด",
};
